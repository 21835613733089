var oneDarkPro = Object.freeze({
  "colors": {
    "activityBar.background": "#282c34",
    "activityBar.foreground": "#d7dae0",
    "activityBarBadge.background": "#4d78cc",
    "activityBarBadge.foreground": "#f8fafd",
    "badge.background": "#282c34",
    "button.background": "#404754",
    "button.secondaryBackground": "#30333d",
    "button.secondaryForeground": "#c0bdbd",
    "checkbox.border": "#404754",
    "debugToolBar.background": "#21252b",
    "descriptionForeground": "#abb2bf",
    "diffEditor.insertedTextBackground": "#00809b33",
    "dropdown.background": "#21252b",
    "dropdown.border": "#21252b",
    "editor.background": "#282c34",
    "editor.findMatchBackground": "#d19a6644",
    "editor.findMatchBorder": "#ffffff5a",
    "editor.findMatchHighlightBackground": "#ffffff22",
    "editor.foreground": "#abb2bf",
    "editor.lineHighlightBackground": "#2c313c",
    "editor.selectionBackground": "#67769660",
    "editor.selectionHighlightBackground": "#ffffff10",
    "editor.selectionHighlightBorder": "#dddddd",
    "editor.wordHighlightBackground": "#d2e0ff2f",
    "editor.wordHighlightBorder": "#7f848e",
    "editor.wordHighlightStrongBackground": "#abb2bf26",
    "editor.wordHighlightStrongBorder": "#7f848e",
    "editorBracketHighlight.foreground1": "#d19a66",
    "editorBracketHighlight.foreground2": "#c678dd",
    "editorBracketHighlight.foreground3": "#56b6c2",
    "editorBracketMatch.background": "#515a6b",
    "editorBracketMatch.border": "#515a6b",
    "editorCursor.background": "#ffffffc9",
    "editorCursor.foreground": "#528bff",
    "editorError.foreground": "#c24038",
    "editorGroup.background": "#181a1f",
    "editorGroup.border": "#181a1f",
    "editorGroupHeader.tabsBackground": "#21252b",
    "editorGutter.addedBackground": "#109868",
    "editorGutter.deletedBackground": "#9A353D",
    "editorGutter.modifiedBackground": "#948B60",
    "editorHoverWidget.background": "#21252b",
    "editorHoverWidget.border": "#181a1f",
    "editorHoverWidget.highlightForeground": "#61afef",
    "editorIndentGuide.activeBackground": "#c8c8c859",
    "editorIndentGuide.background": "#3b4048",
    "editorInlayHint.background": "#2c313c",
    "editorInlayHint.foreground": "#abb2bf",
    "editorLineNumber.activeForeground": "#abb2bf",
    "editorLineNumber.foreground": "#495162",
    "editorMarkerNavigation.background": "#21252b",
    "editorOverviewRuler.addedBackground": "#109868",
    "editorOverviewRuler.deletedBackground": "#9A353D",
    "editorOverviewRuler.modifiedBackground": "#948B60",
    "editorRuler.foreground": "#abb2bf26",
    "editorSuggestWidget.background": "#21252b",
    "editorSuggestWidget.border": "#181a1f",
    "editorSuggestWidget.selectedBackground": "#2c313a",
    "editorWarning.foreground": "#d19a66",
    "editorWhitespace.foreground": "#ffffff1d",
    "editorWidget.background": "#21252b",
    "focusBorder": "#3e4452",
    "gitDecoration.ignoredResourceForeground": "#636b78",
    "input.background": "#1d1f23",
    "input.foreground": "#abb2bf",
    "list.activeSelectionBackground": "#2c313a",
    "list.activeSelectionForeground": "#d7dae0",
    "list.focusBackground": "#323842",
    "list.focusForeground": "#f0f0f0",
    "list.highlightForeground": "#ecebeb",
    "list.hoverBackground": "#2c313a",
    "list.hoverForeground": "#abb2bf",
    "list.inactiveSelectionBackground": "#323842",
    "list.inactiveSelectionForeground": "#d7dae0",
    "list.warningForeground": "#d19a66",
    "menu.foreground": "#abb2bf",
    "menu.separatorBackground": "#343a45",
    "minimapGutter.addedBackground": "#109868",
    "minimapGutter.deletedBackground": "#9A353D",
    "minimapGutter.modifiedBackground": "#948B60",
    "panel.border": "#3e4452",
    "panelSectionHeader.background": "#21252b",
    "peekViewEditor.background": "#1b1d23",
    "peekViewEditor.matchHighlightBackground": "#29244b",
    "peekViewResult.background": "#22262b",
    "scrollbar.shadow": "#23252c",
    "scrollbarSlider.activeBackground": "#747d9180",
    "scrollbarSlider.background": "#4e566660",
    "scrollbarSlider.hoverBackground": "#5a637580",
    "settings.focusedRowBackground": "#282c34",
    "settings.headerForeground": "#fff",
    "sideBar.background": "#21252b",
    "sideBar.foreground": "#abb2bf",
    "sideBarSectionHeader.background": "#282c34",
    "sideBarSectionHeader.foreground": "#abb2bf",
    "statusBar.background": "#21252b",
    "statusBar.debuggingBackground": "#cc6633",
    "statusBar.debuggingBorder": "#ff000000",
    "statusBar.debuggingForeground": "#ffffff",
    "statusBar.foreground": "#9da5b4",
    "statusBar.noFolderBackground": "#21252b",
    "statusBarItem.remoteBackground": "#4d78cc",
    "statusBarItem.remoteForeground": "#f8fafd",
    "tab.activeBackground": "#282c34",
    "tab.activeBorder": "#b4b4b4",
    "tab.activeForeground": "#dcdcdc",
    "tab.border": "#181a1f",
    "tab.hoverBackground": "#323842",
    "tab.inactiveBackground": "#21252b",
    "tab.unfocusedHoverBackground": "#323842",
    "terminal.ansiBlack": "#3f4451",
    "terminal.ansiBlue": "#4aa5f0",
    "terminal.ansiBrightBlack": "#4f5666",
    "terminal.ansiBrightBlue": "#4dc4ff",
    "terminal.ansiBrightCyan": "#4cd1e0",
    "terminal.ansiBrightGreen": "#a5e075",
    "terminal.ansiBrightMagenta": "#de73ff",
    "terminal.ansiBrightRed": "#ff616e",
    "terminal.ansiBrightWhite": "#e6e6e6",
    "terminal.ansiBrightYellow": "#f0a45d",
    "terminal.ansiCyan": "#42b3c2",
    "terminal.ansiGreen": "#8cc265",
    "terminal.ansiMagenta": "#c162de",
    "terminal.ansiRed": "#e05561",
    "terminal.ansiWhite": "#d7dae0",
    "terminal.ansiYellow": "#d18f52",
    "terminal.background": "#282c34",
    "terminal.border": "#3e4452",
    "terminal.foreground": "#abb2bf",
    "terminal.selectionBackground": "#abb2bf30",
    "textBlockQuote.background": "#2e3440",
    "textBlockQuote.border": "#4b5362",
    "textLink.foreground": "#61afef",
    "textPreformat.foreground": "#d19a66",
    "titleBar.activeBackground": "#282c34",
    "titleBar.activeForeground": "#9da5b4",
    "titleBar.inactiveBackground": "#282c34",
    "titleBar.inactiveForeground": "#6b717d",
    "tree.indentGuidesStroke": "#ffffff1d",
    "walkThrough.embeddedEditorBackground": "#2e3440",
    "welcomePage.buttonHoverBackground": "#404754"
  },
  "displayName": "One Dark Pro",
  "name": "one-dark-pro",
  "semanticHighlighting": true,
  "semanticTokenColors": {
    "annotation:dart": {
      "foreground": "#d19a66"
    },
    "enumMember": {
      "foreground": "#56b6c2"
    },
    "macro": {
      "foreground": "#d19a66"
    },
    "memberOperatorOverload": {
      "foreground": "#c678dd"
    },
    "parameter.label:dart": {
      "foreground": "#abb2bf"
    },
    "property:dart": {
      "foreground": "#d19a66"
    },
    "tomlArrayKey": {
      "foreground": "#e5c07b"
    },
    "variable.constant": {
      "foreground": "#d19a66"
    },
    "variable.defaultLibrary": {
      "foreground": "#e5c07b"
    },
    "variable:dart": {
      "foreground": "#d19a66"
    }
  },
  "tokenColors": [
    {
      "scope": "meta.embedded",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "punctuation.definition.delayed.unison,punctuation.definition.list.begin.unison,punctuation.definition.list.end.unison,punctuation.definition.ability.begin.unison,punctuation.definition.ability.end.unison,punctuation.operator.assignment.as.unison,punctuation.separator.pipe.unison,punctuation.separator.delimiter.unison,punctuation.definition.hash.unison",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "variable.other.generic-type.haskell",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "storage.type.haskell",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "support.variable.magic.python",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "punctuation.separator.period.python,punctuation.separator.element.python,punctuation.parenthesis.begin.python,punctuation.parenthesis.end.python",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "variable.parameter.function.language.special.self.python",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "variable.parameter.function.language.special.cls.python",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "storage.modifier.lifetime.rust",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "support.function.std.rust",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "entity.name.lifetime.rust",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "variable.language.rust",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "support.constant.edge",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "constant.other.character-class.regexp",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "keyword.operator.word"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "keyword.operator.quantifier.regexp",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "variable.parameter.function",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "comment markup.link",
      "settings": {
        "foreground": "#5c6370"
      }
    },
    {
      "scope": "markup.changed.diff",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "meta.diff.header.from-file,meta.diff.header.to-file,punctuation.definition.from-file.diff,punctuation.definition.to-file.diff",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "markup.inserted.diff",
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": "markup.deleted.diff",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "meta.function.c,meta.function.cpp",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "punctuation.section.block.begin.bracket.curly.cpp,punctuation.section.block.end.bracket.curly.cpp,punctuation.terminator.statement.c,punctuation.section.block.begin.bracket.curly.c,punctuation.section.block.end.bracket.curly.c,punctuation.section.parens.begin.bracket.round.c,punctuation.section.parens.end.bracket.round.c,punctuation.section.parameters.begin.bracket.round.c,punctuation.section.parameters.end.bracket.round.c",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "punctuation.separator.key-value",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "keyword.operator.expression.import",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "support.constant.math",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "support.constant.property.math",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "variable.other.constant",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "storage.type.annotation.java",
        "storage.type.object.array.java"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "source.java",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "punctuation.section.block.begin.java,punctuation.section.block.end.java,punctuation.definition.method-parameters.begin.java,punctuation.definition.method-parameters.end.java,meta.method.identifier.java,punctuation.section.method.begin.java,punctuation.section.method.end.java,punctuation.terminator.java,punctuation.section.class.begin.java,punctuation.section.class.end.java,punctuation.section.inner-class.begin.java,punctuation.section.inner-class.end.java,meta.method-call.java,punctuation.section.class.begin.bracket.curly.java,punctuation.section.class.end.bracket.curly.java,punctuation.section.method.begin.bracket.curly.java,punctuation.section.method.end.bracket.curly.java,punctuation.separator.period.java,punctuation.bracket.angle.java,punctuation.definition.annotation.java,meta.method.body.java",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "meta.method.java",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "storage.modifier.import.java,storage.type.java,storage.type.generic.java",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "keyword.operator.instanceof.java",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "meta.definition.variable.name.java",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "keyword.operator.logical",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "keyword.operator.bitwise",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "keyword.operator.channel",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "support.constant.property-value.scss,support.constant.property-value.css",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "keyword.operator.css,keyword.operator.scss,keyword.operator.less",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "support.constant.color.w3c-standard-color-name.css,support.constant.color.w3c-standard-color-name.scss",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "punctuation.separator.list.comma.css",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "support.constant.color.w3c-standard-color-name.css",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "support.type.vendored.property-name.css",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "support.module.node,support.type.object.module,support.module.node",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "entity.name.type.module",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "variable.other.readwrite,meta.object-literal.key,support.variable.property,support.variable.object.process,support.variable.object.node",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "support.constant.json",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": [
        "keyword.operator.expression.instanceof",
        "keyword.operator.new",
        "keyword.operator.ternary",
        "keyword.operator.optional",
        "keyword.operator.expression.keyof"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "support.type.object.console",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "support.variable.property.process",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "entity.name.function,support.function.console",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "keyword.operator.misc.rust",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "keyword.operator.sigil.rust",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "keyword.operator.delete",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "support.type.object.dom",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "support.variable.dom,support.variable.property.dom",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "keyword.operator.arithmetic,keyword.operator.comparison,keyword.operator.decrement,keyword.operator.increment,keyword.operator.relational",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "keyword.operator.assignment.c,keyword.operator.comparison.c,keyword.operator.c,keyword.operator.increment.c,keyword.operator.decrement.c,keyword.operator.bitwise.shift.c,keyword.operator.assignment.cpp,keyword.operator.comparison.cpp,keyword.operator.cpp,keyword.operator.increment.cpp,keyword.operator.decrement.cpp,keyword.operator.bitwise.shift.cpp",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "punctuation.separator.delimiter",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "punctuation.separator.c,punctuation.separator.cpp",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "support.type.posix-reserved.c,support.type.posix-reserved.cpp",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "keyword.operator.sizeof.c,keyword.operator.sizeof.cpp",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "variable.parameter.function.language.python",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "support.type.python",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "keyword.operator.logical.python",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "variable.parameter.function.python",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "punctuation.definition.arguments.begin.python,punctuation.definition.arguments.end.python,punctuation.separator.arguments.python,punctuation.definition.list.begin.python,punctuation.definition.list.end.python",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "meta.function-call.generic.python",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "constant.character.format.placeholder.other.python",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "keyword.operator",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "keyword.operator.assignment.compound",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "keyword.operator.assignment.compound.js,keyword.operator.assignment.compound.ts",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "entity.name.namespace",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "variable",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "variable.c",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "variable.language",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "token.variable.parameter.java",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "import.storage.java",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "token.package.keyword",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "token.package",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": [
        "entity.name.function",
        "meta.require",
        "support.function.any-method",
        "variable.function"
      ],
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "entity.name.type.namespace",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "support.class, entity.name.type.class",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "entity.name.class.identifier.namespace.type",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "entity.name.class",
        "variable.other.class.js",
        "variable.other.class.ts"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "variable.other.class.php",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "entity.name.type",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "keyword.control",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "control.elements, keyword.operator.less",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "keyword.other.special-method",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "storage",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "token.storage",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "keyword.operator.expression.delete,keyword.operator.expression.in,keyword.operator.expression.of,keyword.operator.expression.instanceof,keyword.operator.new,keyword.operator.expression.typeof,keyword.operator.expression.void",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "token.storage.type.java",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "support.function",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "support.type.property-name",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "support.type.property-name.toml, support.type.property-name.table.toml, support.type.property-name.array.toml",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "support.constant.property-value",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "support.constant.font-name",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "meta.tag",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "string",
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": "constant.other.symbol",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "constant.numeric",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "constant",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "punctuation.definition.constant",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "entity.name.tag",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "entity.other.attribute-name",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "entity.other.attribute-name.id",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "entity.other.attribute-name.class.css",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "meta.selector",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "markup.heading",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "markup.heading punctuation.definition.heading, entity.name.section",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "keyword.other.unit",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "markup.bold,todo.bold",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "punctuation.definition.bold",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "markup.italic, punctuation.definition.italic,todo.emphasis",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "emphasis md",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "entity.name.section.markdown",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "punctuation.definition.heading.markdown",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "punctuation.definition.list.begin.markdown",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "markup.heading.setext",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "punctuation.definition.bold.markdown",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "markup.inline.raw.markdown",
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": "markup.inline.raw.string.markdown",
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": "punctuation.definition.raw.markdown",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "punctuation.definition.list.markdown",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "punctuation.definition.string.begin.markdown",
        "punctuation.definition.string.end.markdown",
        "punctuation.definition.metadata.markdown"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "beginning.punctuation.definition.list.markdown"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "punctuation.definition.metadata.markdown",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "markup.underline.link.markdown,markup.underline.link.image.markdown",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "string.other.link.title.markdown,string.other.link.description.markdown",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "markup.raw.monospace.asciidoc",
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": "punctuation.definition.asciidoc",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "markup.list.asciidoc",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "markup.link.asciidoc,markup.other.url.asciidoc",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "string.unquoted.asciidoc,markup.other.url.asciidoc",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "string.regexp",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "punctuation.section.embedded, variable.interpolation",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "punctuation.section.embedded.begin,punctuation.section.embedded.end",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "invalid.illegal",
      "settings": {
        "foreground": "#ffffff"
      }
    },
    {
      "scope": "invalid.illegal.bad-ampersand.html",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "invalid.illegal.unrecognized-tag.html",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "invalid.broken",
      "settings": {
        "foreground": "#ffffff"
      }
    },
    {
      "scope": "invalid.deprecated",
      "settings": {
        "foreground": "#ffffff"
      }
    },
    {
      "scope": "invalid.deprecated.entity.other.attribute-name.html",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "invalid.unimplemented",
      "settings": {
        "foreground": "#ffffff"
      }
    },
    {
      "scope": "source.json meta.structure.dictionary.json > string.quoted.json",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "source.json meta.structure.dictionary.json > string.quoted.json > punctuation.string",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "source.json meta.structure.dictionary.json > value.json > string.quoted.json,source.json meta.structure.array.json > value.json > string.quoted.json,source.json meta.structure.dictionary.json > value.json > string.quoted.json > punctuation,source.json meta.structure.array.json > value.json > string.quoted.json > punctuation",
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": "source.json meta.structure.dictionary.json > constant.language.json,source.json meta.structure.array.json > constant.language.json",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "support.type.property-name.json",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "support.type.property-name.json punctuation",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "text.html.laravel-blade source.php.embedded.line.html entity.name.tag.laravel-blade",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "text.html.laravel-blade source.php.embedded.line.html support.constant.laravel-blade",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "support.other.namespace.use.php,support.other.namespace.use-as.php,entity.other.alias.php,meta.interface.php",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "keyword.operator.error-control.php",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "keyword.operator.type.php",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "punctuation.section.array.begin.php",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "punctuation.section.array.end.php",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "invalid.illegal.non-null-typehinted.php",
      "settings": {
        "foreground": "#f44747"
      }
    },
    {
      "scope": "storage.type.php,meta.other.type.phpdoc.php,keyword.other.type.php,keyword.other.array.phpdoc.php",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "meta.function-call.php,meta.function-call.object.php,meta.function-call.static.php",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "punctuation.definition.parameters.begin.bracket.round.php,punctuation.definition.parameters.end.bracket.round.php,punctuation.separator.delimiter.php,punctuation.section.scope.begin.php,punctuation.section.scope.end.php,punctuation.terminator.expression.php,punctuation.definition.arguments.begin.bracket.round.php,punctuation.definition.arguments.end.bracket.round.php,punctuation.definition.storage-type.begin.bracket.round.php,punctuation.definition.storage-type.end.bracket.round.php,punctuation.definition.array.begin.bracket.round.php,punctuation.definition.array.end.bracket.round.php,punctuation.definition.begin.bracket.round.php,punctuation.definition.end.bracket.round.php,punctuation.definition.begin.bracket.curly.php,punctuation.definition.end.bracket.curly.php,punctuation.definition.section.switch-block.end.bracket.curly.php,punctuation.definition.section.switch-block.start.bracket.curly.php,punctuation.definition.section.switch-block.begin.bracket.curly.php,punctuation.definition.section.switch-block.end.bracket.curly.php",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "support.constant.core.rust",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "support.constant.ext.php,support.constant.std.php,support.constant.core.php,support.constant.parser-token.php",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "entity.name.goto-label.php,support.other.php",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "keyword.operator.logical.php,keyword.operator.bitwise.php,keyword.operator.arithmetic.php",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "keyword.operator.regexp.php",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "keyword.operator.comparison.php",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "keyword.operator.heredoc.php,keyword.operator.nowdoc.php",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "meta.function.decorator.python",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "support.token.decorator.python,meta.function.decorator.identifier.python",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "function.parameter",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "function.brace",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "function.parameter.ruby, function.parameter.cs",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "constant.language.symbol.ruby",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "constant.language.symbol.hashkey.ruby",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "rgb-value",
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": "inline-color-decoration rgb-value",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "less rgb-value",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "selector.sass",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "support.type.primitive.ts,support.type.builtin.ts,support.type.primitive.tsx,support.type.builtin.tsx",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "block.scope.end,block.scope.begin",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "storage.type.cs",
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "entity.name.variable.local.cs",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "token.info-token",
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": "token.warn-token",
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": "token.error-token",
      "settings": {
        "foreground": "#f44747"
      }
    },
    {
      "scope": "token.debug-token",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": [
        "punctuation.definition.template-expression.begin",
        "punctuation.definition.template-expression.end",
        "punctuation.section.embedded"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": [
        "meta.template.expression"
      ],
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": [
        "keyword.operator.module"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": [
        "support.type.type.flowtype"
      ],
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": [
        "support.type.primitive"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "meta.property.object"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "variable.parameter.function.js"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "keyword.other.template.begin"
      ],
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": [
        "keyword.other.template.end"
      ],
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": [
        "keyword.other.substitution.begin"
      ],
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": [
        "keyword.other.substitution.end"
      ],
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": [
        "keyword.operator.assignment"
      ],
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": [
        "keyword.operator.assignment.go"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "keyword.operator.arithmetic.go",
        "keyword.operator.address.go"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": [
        "keyword.operator.arithmetic.c",
        "keyword.operator.arithmetic.cpp"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": [
        "entity.name.package.go"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "support.type.prelude.elm"
      ],
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": [
        "support.constant.elm"
      ],
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": [
        "punctuation.quasi.element"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": [
        "constant.character.entity"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "entity.other.attribute-name.pseudo-element",
        "entity.other.attribute-name.pseudo-class"
      ],
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": [
        "entity.global.clojure"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "meta.symbol.clojure"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "constant.keyword.clojure"
      ],
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": [
        "meta.arguments.coffee",
        "variable.parameter.function.coffee"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "source.ini"
      ],
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": [
        "meta.scope.prerequisites.makefile"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "source.makefile"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "storage.modifier.import.groovy"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "meta.method.groovy"
      ],
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": [
        "meta.definition.variable.name.groovy"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "meta.definition.class.inherited.classes.groovy"
      ],
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": [
        "support.variable.semantic.hlsl"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "support.type.texture.hlsl",
        "support.type.sampler.hlsl",
        "support.type.object.hlsl",
        "support.type.object.rw.hlsl",
        "support.type.fx.hlsl",
        "support.type.object.hlsl"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": [
        "text.variable",
        "text.bracketed"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "support.type.swift",
        "support.type.vb.asp"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "entity.name.function.xi"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "entity.name.class.xi"
      ],
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": [
        "constant.character.character-class.regexp.xi"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "constant.regexp.xi"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": [
        "keyword.control.xi"
      ],
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": [
        "invalid.xi"
      ],
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": [
        "beginning.punctuation.definition.quote.markdown.xi"
      ],
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": [
        "beginning.punctuation.definition.list.markdown.xi"
      ],
      "settings": {
        "foreground": "#7f848e"
      }
    },
    {
      "scope": [
        "constant.character.xi"
      ],
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": [
        "accent.xi"
      ],
      "settings": {
        "foreground": "#61afef"
      }
    },
    {
      "scope": [
        "wikiword.xi"
      ],
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": [
        "constant.other.color.rgb-value.xi"
      ],
      "settings": {
        "foreground": "#ffffff"
      }
    },
    {
      "scope": [
        "punctuation.definition.tag.xi"
      ],
      "settings": {
        "foreground": "#5c6370"
      }
    },
    {
      "scope": [
        "entity.name.label.cs",
        "entity.name.scope-resolution.function.call",
        "entity.name.scope-resolution.function.definition"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "entity.name.label.cs",
        "markup.heading.setext.1.markdown",
        "markup.heading.setext.2.markdown"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        " meta.brace.square"
      ],
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": "comment, punctuation.definition.comment",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#7f848e"
      }
    },
    {
      "scope": "markup.quote.markdown",
      "settings": {
        "foreground": "#5c6370"
      }
    },
    {
      "scope": "punctuation.definition.block.sequence.item.yaml",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": [
        "constant.language.symbol.elixir",
        "constant.language.symbol.double-quoted.elixir"
      ],
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": [
        "entity.name.variable.parameter.cs"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "entity.name.variable.field.cs"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "markup.deleted",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "markup.inserted",
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": "markup.underline",
      "settings": {
        "fontStyle": "underline"
      }
    },
    {
      "scope": [
        "punctuation.section.embedded.begin.php",
        "punctuation.section.embedded.end.php"
      ],
      "settings": {
        "foreground": "#BE5046"
      }
    },
    {
      "scope": [
        "support.other.namespace.php"
      ],
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": [
        "variable.parameter.function.latex"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "variable.other.object"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "variable.other.constant.property"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "entity.other.inherited-class"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": "variable.other.readwrite.c",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "entity.name.variable.parameter.php,punctuation.separator.colon.php,constant.other.php",
      "settings": {
        "foreground": "#abb2bf"
      }
    },
    {
      "scope": [
        "constant.numeric.decimal.asm.x86_64"
      ],
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": [
        "support.other.parenthesis.regexp"
      ],
      "settings": {
        "foreground": "#d19a66"
      }
    },
    {
      "scope": [
        "constant.character.escape"
      ],
      "settings": {
        "foreground": "#56b6c2"
      }
    },
    {
      "scope": [
        "string.regexp"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": [
        "log.info"
      ],
      "settings": {
        "foreground": "#98c379"
      }
    },
    {
      "scope": [
        "log.warning"
      ],
      "settings": {
        "foreground": "#e5c07b"
      }
    },
    {
      "scope": [
        "log.error"
      ],
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "keyword.operator.expression.is",
      "settings": {
        "foreground": "#c678dd"
      }
    },
    {
      "scope": "entity.name.label",
      "settings": {
        "foreground": "#e06c75"
      }
    },
    {
      "scope": "entity.other.attribute-name.js,entity.other.attribute-name.ts,entity.other.attribute-name.jsx,entity.other.attribute-name.tsx,variable.parameter,variable.language.super",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": "comment.line.double-slash,comment.block.documentation",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": "markup.italic.markdown",
      "settings": {
        "fontStyle": "italic"
      }
    }
  ],
  "type": "dark"
});

export { oneDarkPro as default };
